<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="5" :sm="24">
                <a-form-item label="用户昵称" prop="nickname">
                <a-input v-model="queryParam.nickname" placeholder="请输入用户昵称" allow-clear/>
              </a-form-item>
            </a-col>


            <a-col :md="5" :sm="24">
              <a-form-item label="帖子内容" prop="content">
                <a-input v-model="queryParam.content" placeholder="请输入内容" allow-clear/>
              </a-form-item>
            </a-col>


            <a-col :md="5" :sm="24">
              <a-form-item label="标签" prop="tagTitle">
                <a-input v-model="queryParam.tagTitle" placeholder="请输入标签" allow-clear/>
              </a-form-item>
            </a-col>

            <template v-if="advanced">
              <a-col :md="5" :sm="24">
                <a-form-item label="审核状态" prop="state">
                  <a-select v-model="queryParam.state">
                    <a-select-option :key="0">待审核</a-select-option>
                    <a-select-option :key="1">通过</a-select-option>
                    <a-select-option :key="2">拒绝</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :md="5" :sm="24">
                <a-form-item label="用户ShowId" prop="showId">
                  <a-input v-model="queryParam.showId" placeholder="请输入用户ShowId" allow-clear/>
                </a-form-item>
              </a-col>


              <a-col :md="5" :sm="24">
                <a-form-item label="时间筛选">
                  <a-date-picker style="width: 100%" v-model="queryParam.createTime" format="YYYY-MM-DD" allow-clear/>
                </a-form-item>
              </a-col>


            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['dynamic:dynamic:add']">-->
<!--          <a-icon type="plus" />新增-->
<!--        </a-button>-->
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['dynamic:dynamic:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['dynamic:dynamic:remove']">-->
<!--          <a-icon type="delete" />删除-->
<!--        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['dynamic:dynamic:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a   v-if="record.state==='0'" @click="$refs.createForm.handleUpdate(record, undefined)" >审核</a>
          <a   v-if="record.state==='1' || record.state==='2'" @click="$refs.createForm.handleUpdate(record, undefined)" >详情</a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageDynamic,listDynamic, delDynamic } from '@/api/dynamic/dynamic'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import moment from "moment";

export default {
  name: 'Dynamic',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      dateRange: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userId: null,
        type: null,
        showId: null,
        content: null,
        files: null,
        nickname: null,

        cover: null,
        tagId: null,
        tagTitle: null,
        location: null,
        province: null,
        city: null,
        area: null,
        address: null,
        state: null,
        rejectCause: null,
        duration: null,
        viewTotal: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '用户昵称',
          dataIndex: 'nickname',
          
          align: 'center'
        },
        {
          title: '用户ShowId',
          dataIndex: 'showId',

          align: 'center'
        },
        {
          title: '标签名称',
          dataIndex: 'tagTitle',
          
          align: 'center'
        },
        // {
        //   title: '省',
        //   dataIndex: 'province',
        //
        //   align: 'center'
        // },
        // {
        //   title: '市',
        //   dataIndex: 'city',
        //
        //   align: 'center'
        // },
        // {
        //   title: '区/县',
        //   dataIndex: 'area',
        //
        //   align: 'center'
        // },
        // {
        //   title: '详细地址',
        //   dataIndex: 'address',
        //
        //
        //   align: 'center'
        // },
        {
          title: '审核状态',
          dataIndex: 'state',
          
          customRender: function (t, r, index) {
            if(t == 0) {
              return '待审核'
            } else if(t == 1) {
              return '通过'
            } else{
              return '拒绝'
            }
          },
          align: 'center'
        },
        // {
        //   title: '拒绝原因',
        //   dataIndex: 'rejectCause',
        //
        //   align: 'center'
        // },
        // {
        //   title: '视频时长',
        //   dataIndex: 'duration',
        //   
        //   align: 'center'
        // },
        {
          title: '点赞量',
          dataIndex: 'likeNum',
          
          align: 'center'
        },
        {
          title: '发布时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          
          align: 'center'
        },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   
        //   align: 'center'
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询动态列表 */
    getList () {
      this.loading = true
      if (moment.isMoment(this.queryParam.createTime)) {
        this.queryParam.createTime = this.queryParam.createTime.format('YYYY-MM-DD')
      }
     pageDynamic(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userId: undefined,
        type: undefined,
        content: undefined,
        files: undefined,
        cover: undefined,
        tagId: undefined,
        tagTitle: undefined,
        location: undefined,
        province: undefined,
        city: undefined,
        area: undefined,
        address: undefined,
        state: undefined,
        rejectCause: undefined,
        duration: undefined,
        viewTotal: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delDynamic(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('dynamic/dynamic/export', {
            ...that.queryParam
          }, `动态_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
